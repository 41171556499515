<template>
  <div>
    <bread-cum v-bind:breadcrumbItems="['แพลตฟอร์ม', 'เปลี่ยนแพลตฟอร์ม']" />
    <div style="width: 400px">
      <v-select
        v-if="platformList"
        label="name"
        :options="platformList"
        v-model="form.platformId"
        :reduce="(name) => name.id"
        :disabled="!canEdit"
        :dropdown-should-open="dropdownShouldOpen"
      ></v-select>
    </div>
    <button
      class="btn btn-primary mt-2"
      @click="changePlatform"
      v-show="canEdit"
    >
      ตกลง
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {},
      platformList: null,
    }
  },
  computed: {
    canEdit() {
      return this.$store.state.auth.user?.userData?.permissions?.includes(
        "platform_change_edit"
      )
    },
  },
  methods: {
    async changePlatform() {
      if (!this.form.platformId) {
        this.swal.error("กรุณาเลือก platform")
        return
      }

      this.swal.processing()

      try {
        let res = await this.axios({
          method: "post",
          url: "user/gen/access-token",
          data: this.form,
        })

        let user = JSON.parse(localStorage.getItem("user"))
        user.accessToken = res.data.data
        localStorage.setItem("user", JSON.stringify(user))

        window.location.reload()
      } catch (e) {
        this.swal.catchError(e)
      }
    },
    dropdownShouldOpen(VueSelect) {
      return VueSelect.search.length >= 2 && VueSelect.open
    },
  },
  async mounted() {
    this.swal.processing()

    try {
      const res = await this.axios({
        method: "get",
        url: "platform/all",
      })

      res.data.data?.forEach((item) => {
        item.name = `${item.code} (${item.name})`
      })

      this.platformList = res.data.data

      this.swal.close()
    } catch (e) {
      this.swal.catchError(e)
    }
  },
}
</script>

<style scoped>
@import "~vue-select/dist/vue-select.css";

.v-select {
  --vs-controls-color: #664cc3;
  --vs-border-color: #664cc3;

  --vs-dropdown-bg: #282c34;
  --vs-dropdown-color: #cc99cd;
  --vs-dropdown-option-color: #cc99cd;

  --vs-selected-bg: #664cc3;
  --vs-selected-color: #eeeeee;

  --vs-search-input-color: #eeeeee;
  --vs-search-input-bg: none;

  --vs-dropdown-option--active-bg: #664cc3;
  --vs-dropdown-option--active-color: #eeeeee;
}
</style>
